import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './course-suggestion-form.module.scss';
import CourseOption from './course-option';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import EmptySpace from '@components/ui/empty-space';
import { v4 as uuidv4 } from 'uuid';
import scrollTo from '@utilities/scroll-to';

const formName = 'cursos sugeridos';

const cleanFromEmptyValues = obj => {
  try {
    Object.keys(obj).forEach((key) => {
      const value = String(obj[key]);
      if (value !== null && value !== undefined) {
        const trimmed = value.trim();
        if (trimmed !== null && trimmed !== undefined) {
          if (trimmed.length === 0) {
            delete obj[key];
          }
        }
      }
    });
  } catch (error) {
    return {};
  }

  return { ...obj };
};

const encode = (data) => {
  return Object.keys(data).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&");
};

const CourseSuggestionForm = (props) => {
  const breakpoints = useBreakpoint();

  // -------------------------------------------- State

  const [formState, setFormState] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [showEmptyFormMessage, setShowEmptyFormMessage] = useState(false);
  const [formFailed, setFormFailed] = useState(false);
  const [isFormSubmited, setIsFormSubmitted] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState({});

  // -------------------------------------------- Hooks

  React.useEffect(() => {
    if (isFormValid()) {
      setShowEmptyFormMessage(false);
    }
  }, [formState]);

  // -------------------------------------------- Helper methods

  const isFormValid = () => {
    // at least one value needs to be "true" or contain some tex
    const validOptions = Object.entries(formState).filter(entrie => {
      if (entrie[0] !== 'sugestão') {
         return entrie[1] !== 'false';
      }

      return true;
     });

     return validOptions.length > 0;
  };

  const handleChange = (name, isChecked) => {
    const newState = cleanFromEmptyValues({ ...formState, [name]: `${isChecked}` });
    setFormState(newState);
  };

  const handleOptionChange = (courseOptionId, str, hasInputField = false) => {

    const option = checkedOptions[courseOptionId] || { isChecked: false, value: '' };

    const isChecked = hasInputField ? option.value.trim().length > 0 : !option.isChecked;

    setCheckedOptions({ ...checkedOptions, [courseOptionId]: { isChecked: isChecked, value: str }});

    if (hasInputField) {
      handleChange('sugestão', str);
    } else {
      handleChange(str, isChecked);
    }
  };

  const isOptionChecked = (optionId, str, hasInputField) => {
    if (hasInputField) {
      return str.trim().length > 0;
    }

    const option = checkedOptions[optionId];
    if (option) {
      return option.isChecked;
    }
    return false;
  };

  const handleFormSubmit = event => {
    // prevent browser from submiting the form and refreshing the page
    event.preventDefault();

    if (isFormSubmited || isSubmitting || formFailed) {
      return undefined;
    }

    if (!isFormValid()) {
      setShowEmptyFormMessage(true);
      return undefined;
    }

    setShowEmptyFormMessage(false);
    setFormFailed(false);
    setIsFormSubmitted(false);
    setSubmitting(true);

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": formName, id: uuidv4(), ...formState })
    };

    // timeout was added in order to provide better UX

    setTimeout(() => {
      fetch("/submit", options)
        .then(() => {
          setIsFormSubmitted(true);
          setTimeout(() => {
            scrollTo('#suggestions-success-message');
          }, 200);
        })
        .catch(() => {
          setFormFailed(true);
          setSubmitting(false);
          setIsFormSubmitted(false);
          setTimeout(() => {
            scrollTo('#suggestions-error-message');
          }, 200);
        });
    }, 1000);
  };

  // -------------------------------------------- Styles

  const gridStyle = breakpoints.mobile ? {} : {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${breakpoints.mobile ? 345 : 350}px, 1fr))`,
    gridColumnGap: '30px',
    gridRowGap: '20px',
  };

  // -------------------------------------------- JSX elements

  const renderHeader = (
    <div>
      <h2 style={{ fontSize: 20 }}>Não encontras o que procuras?</h2>
      <p style={{ fontSize: breakpoints.mobile ? 12 : 15, marginBottom: breakpoints.mobile ? 55 : 45 }}>
        Vota nesta lista de possíveis cursos ou sugere um curso.
      </p>
    </div>
  );

  const renderSubmitionButton = (
    <React.Fragment>
      <button
        aria-label="submeter sugestões"
        className={styles.submitButton}
        style={{ cursor: (isFormSubmited || isSubmitting || !isFormValid()) ? 'not-allowed' : 'pointer' }}
        type="submit"
      >
        submeter
        {isSubmitting && <FontAwesomeIcon spin={true} icon={faSpinner} size="1x" style={{ marginLeft: 20 }} />}
      </button>
    {
      breakpoints.mobile &&
      <label style={{ color: 'red', fontSize: 12, marginTop: 15, marginLeft: 15, visibility: showEmptyFormMessage ? 'visible' : 'hidden' }}>Escolhe uma opção primeiro</label>
    }
    </React.Fragment>
  );

  const renderSuccessMessage = (
    <div id="suggestions-success-message">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon color="#25e25e" icon={faCheckCircle} size="2x" />
        <h2 style={{ marginLeft: 20, fontSize: breakpoints.mobile ? 20 : 30 }}>Obrigado</h2>
      </div>
      <p style={{ fontSize: breakpoints.mobile ? 12 : 15, margin: 0 }}>As sugestões foram submetidas com sucesso.</p>
    </div>
  );

  const renderErrorMessage = (
    <div id="suggestions-error-message">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon color="#f7496b" icon={faExclamationCircle} size="2x" />
        <h2 style={{ marginLeft: 20, fontSize: breakpoints.mobile ? 20 : 30 }}>A submissão falhou</h2>
      </div>
      <p style={{ fontSize: breakpoints.mobile ? 12 : 15, margin: 0 }}>Por favor tente mais tarde.</p>
    </div>
  );

  // -------------------------------------------- Render

  if (formFailed) {
    return (
      <React.Fragment>
        <EmptySpace desktop={{ margin: isFormSubmited ? 100 : 200, isLineVisible: false }} mobile={{ margin: 160, isLineVisible: true }} />
        {renderErrorMessage}
        <EmptySpace />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <EmptySpace desktop={{ margin: isFormSubmited ? 100 : 200, isLineVisible: false }} mobile={{ margin: 160, isLineVisible: true }} />

      {
        isFormSubmited ? renderSuccessMessage :
        <React.Fragment>

          {renderHeader}

          <form onSubmit={handleFormSubmit} name={formName} method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            {/* https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#form-handling-with-static-site-generators */}
            <input type="hidden" name="form-name" value={formName} />
            <input type="hidden" name="id" />

            <div className={styles.container} style={{ ...gridStyle }}>
              {
                props.courseNames.map((courseName, index) => (
                  <div className={styles.courseOption} key={`${index}-${courseName}`}>
                    <CourseOption
                      label={courseName}
                      isChecked={isOptionChecked}
                      onChange={handleOptionChange}
                    />
                  </div>
                ))
              }
            </div>

            <div className={styles.footer} style={{ marginTop: props.courseNames.length ? breakpoints.mobile ? 20 : 100 : 0 }}>
              <CourseOption
                label="Sugere um Curso:"
                isChecked={isOptionChecked}
                onChange={handleOptionChange}
                hasInputField={true}
              />

              {renderSubmitionButton}
            </div>
          </form>
        </React.Fragment>
      }

      <EmptySpace />
    </React.Fragment>
  );
};

export default CourseSuggestionForm;

CourseSuggestionForm.defaultProps = {
  courseNames: [],
};

CourseSuggestionForm.propTypes = {
  courseNames: PropTypes.arrayOf(PropTypes.string),
};
