const scrollTo = (selector) => {
  const element = document.querySelector(selector);

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    return true;
  }

  if (process.env.NODE_ENV !== 'production') {
    console.warn("The selector: '%s' wasn't found in the document.\n", selector);
  }

  return false;
};

export default scrollTo;
