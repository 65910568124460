import React from 'react';
import styles from './desktop-newsletter.module.scss';
import SubscribeForm from '@components/ui/subscribe-form';
import Title from '@components/ui/title';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const DesktopNewsletter = () => {
  const breakpoints = useBreakpoint();
  if (breakpoints.mobile || breakpoints.tablet) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Title value="Mantém-te ligado!" color="white" />
        <p>Subscreve a nossa newsletter e recebe todas as novidades <strong>afterschool</strong> diretamente no teu email!</p>
        <SubscribeForm id="newsletter-subscribe-form" />
      </div>
    </div>
  );
};

export default DesktopNewsletter;
