import React from 'react';
import PropTypes from 'prop-types';
import styles from './course-option.module.scss';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { v4 as uuidv4 } from 'uuid';

const darkSkyBlue = '#3ebff0';
const verylightPink = '#f1f1f1';

const renderLabel = label => (
  <span style={{ marginRight: 25, flexShrink: 0, alignSelf: 'center' }}>
    {label}
  </span>
);

const CourseOption = (props) => {
  const breakpoints = useBreakpoint();

  // -------------------------------------------- State
  const [optionId] = React.useState(uuidv4());
  const [inputValue, setInputValue] = React.useState('');
  const isChecked = props.isChecked(optionId, inputValue, props.hasInputField);

  // -------------------------------------------- Helper methods

  const handleOnClick = () => {
    if (!props.hasInputField) {
      props.onChange(optionId, props.label);
    }
  };

  const handleKeyDown = event => {
    if (!props.hasInputField) {
      if (event.key === 'Enter') {
        props.onChange(optionId, props.label);
      }
    }
  };

  const handleInputChange = value => {
    setInputValue(value);
    props.onChange(optionId, value, props.hasInputField);
  };

  // -------------------------------------------- Render

  return (
    <div
      className={styles.container}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
      style={{
        backgroundColor: isChecked ? darkSkyBlue : verylightPink,
        cursor: props.hasInputField ? 'default' : 'pointer',
      }}
    >
      <input type="checkbox" checked={isChecked} name={props.hasInputField ? '' : props.label} value={isChecked} readOnly/>

      <span className={styles.checkmark}></span>

      <div style={{ display: 'flex', marginLeft: breakpoints.mobile ? 57 : 75, marginRight: 24, width: '100%', height: '100%' }}>
        {
          props.label &&
          !(breakpoints.tablet && props.hasInputField) && // label doesn't fit along side input field, in mobile
          renderLabel(props.label)
        }
        {
          props.hasInputField &&
          <input
            placeholder={breakpoints.tablet ? "Sugere um Curso" : ""}
            type="text"
            name="sugestão"
            className={styles.userSuggestion}
            value={inputValue}
            onChange={e => handleInputChange(e.target.value)}
          />
        }
      </div>
    </div>
  );
};

export default CourseOption;

CourseOption.defaultProps = {
  onChange: () => {},
  isChecked: () => false,
  label: '',
  hasInputField: false,
};

CourseOption.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.func,
  hasInputField: PropTypes.bool,
};
